<template>
  <a-spin :spinning="spin" style="width: 100%; height: 100%">
    <div>
      <div v-if="dataList.length == 0" class="content">
        <div class="noList">
          <span style="margin-bottom: 20px"
            ><a-icon
              type="frown"
              style="margin-right: 10px"
            />你还没有生成桌卡</span
          >
          <a-button type="primary" style="width: 100px" @click="add"
            >生成桌卡</a-button
          >
        </div>
      </div>
      <div class="content" v-if="dataList.length !== 0">
        <div class="content-top">
            <div style="display: flex; align-items: center;justify-content: space-between;">
            <a-input-search
              placeholder="请输入姓名"
              style="width:18vw; margin-right: 15px"
              enter-button="搜索"
              v-model="name"
              @search="onSearch"
              allowClear
            />
            <a-alert type="error" show-icon closable v-if="num > 0">
              <template slot="message">
                <div style="margin-right: 10px">
                  查询到{{ num }}位参会者姓名与桌卡姓名不一致，<a @click="look"
                    >点击查看</a
                  >
                </div>
              </template>
            </a-alert>
          </div>
          <div  class="specialBTN">
            <a-button type="primary" @click="down" style="margin-right: 17px"
              >下载全部</a-button
            >
            <a-button type="primary" @click="add">重新生成</a-button>
           </div>
           
           
        </div>
        <div class="content-list" v-if="cardList.length !== 0">
          <a-row>
            <a-col
              :span="innerWidths > 0 && innerWidths < 1920 ? 6 : 4"
              :style="{ marginBottom: '2%' }"
              class="col"
              v-for="(card, index) in cardList"
              :key="index"
            >
              <div class="list">
                <div class="img">
                  <img
                    :src="`${card.imageUrl}?${Date.now()}`"
                    style="width: 100%; height: 130px"
                  />
                </div>
                <!-- <div class="title">
                  <div style="display:inline-block;">姓名：
                    </div>
                  <a-tooltip>
                    <template slot="title">
                      {{ card.name }}
                    </template>
                    <div class="cardName">{{ card.name }}</div>
                  </a-tooltip>
                  <span v-if="card.updatedTime" class="update">已修改</span>
                </div> -->
                <div class="title">
                  <div style="display: flex">
                    <div>姓名：</div>
                    <a-tooltip>
                      <div slot="title">{{ card.name }}</div>
                      <div
                        style="
                          width: 4em;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                        "
                      >
                        {{ card.name }}
                      </div>
                    </a-tooltip>
                    <span v-if="card.isUpdate === 1" class="update">已修改</span>
                  </div>
                </div>
                <div class="unit">
                  单位：
                  <a-tooltip>
                    <template slot="title">
                      {{ card.hospital }}
                    </template>
                    {{ card.hospital }}
                  </a-tooltip>
                </div>
                <div class="btn">
                  <div  @click="downimgLoad(card)"  target="_blank">下载</div>
                  <span style="color: #d5d5d5; width: 2%">|</span>
                  <div @click="editPeople(card)">修改</div> 
                  <span style="color: #d5d5d5; width: 2%">|</span>
                  <div @click="newAdd(card)">重新生成</div>
                  <!-- 
                <span @click="downKvPsd(card)" style="background:red">下载</span>
                <span style="color: #d5d5d5">|</span>
                <span @click="newAdd(card)">重新生成</span> -->
                </div>
              </div>
            </a-col>
          </a-row>
          <div
            style="
              display: block;
              width: 100%;
              height: 50px;
              text-align: center;
              margin: 20px 0 40px 0;
            "
          >
            <!-- <a-pagination
              :current="pageNo"
              :total="total"
              :pageSize="pageSize"
              show-quick-jumper
              @change="PageNOchange"
               :pageSizeOptions="pageSizeOptions"
                :show-size-changer="true"
              style="display: inline-block"
            /> -->
            <a-pagination
              :total="total"
              :page-size="pageSize"
              :current="pageNo"
              :show-quick-jumper="true"
              :show-size-changer="true"
              :pageSizeOptions="pageSizeOptions"
              @change="PageNOchange"
              @showSizeChange="PageNOchange"
            />
          </div>
        </div>
        <a-empty v-if="cardList.length == 0" style="margin-top: 60px" /> 
      </div>
    </div>

      <a-drawer
        :title="title"
        placement="right"
        @close="addClose"
        :visible="drawlVisible"
        width="550"
        :body-style="{ paddingBottom: '80px' }"
      >
        <a-spin
          tip="桌卡生成中..."
          :spinning="spinning"
          style="width: 100%; height: 100%"
        >
          <a-form-model
            ref="addItem"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item label="选择模板" required>
              <a-radio-group
                class="formImage"
                @change="changeReason"
                :key="addForm.materialType"
                :default-value="addForm.materialType"
              >
                <!-- 
            <a-radio value="TableCardNo1" style="position: relative;">
              <img src="../../assets/模板1.png" alt="" class="image">
                模板1
            </a-radio>
            <a-radio value="TableCardNo2" style="position: relative;margin-top:170px;">
              <img src="../../assets/模板2.png" alt="" class="image">
                模板2
            </a-radio> -->
                <a-radio
                  v-for="(item, index) in templateList"
                  :key="index"
                  :value="item.code"
                  :style="{
                    position: relative,
                    marginTop: `calc(${index}*170px)`,
                  }"
                >
                  <img :src="item.exampleImgUrl" class="image" />
                  {{ item.materialName }}
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="姓名">
              <!-- <a-input  :placeholder="title=='桌卡生成'?'系统自动获取参会人员姓名生成':''"  style="width:290px" v-model="addForm.name" :disabled="title=='桌卡生成'?true:false"> </a-input> -->
              <a-input
                placeholder="系统自动获取参会人员姓名生成"
                disabled
                style="width: 290px"
              >
              </a-input>
            </a-form-model-item>
            <a-form-model-item label="背景图" required>
              <div>
                <div style="display: flex">
                  <div class="uploadImg">
                    <input
                      id="addImgRect"
                      ref="addImgRect"
                      accept="image/*"
                      style="width: 100%; height: 100%; opacity: 0"
                      type="file"
                      @change="uploadImg()"
                    />
                    <div class="hhh" v-if="!addForm.inputUrl">
                      <div style="font-size: 40px">+</div>
                      <div>上传</div>
                    </div>
                    <img
                      v-if="addForm.inputUrl"
                      :src="addForm.inputUrl"
                      alt=""
                      class="upload_gutter"
                    />
                  </div>
                </div>
              </div>
            </a-form-model-item>
          </a-form-model>
        </a-spin>
        <div
          :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button
            :style="{ marginRight: '20px', width: '90px' }"
            @click="addClose"
            :disabled="spinning == true ? true : false"
          >
            取消
          </a-button>
          <a-button
            type="primary"
            style="width: 90px"
            @click="addOk"
            :disabled="spinning == true ? true : false"
          >
            确认
          </a-button>
        </div>
      </a-drawer>

      <a-modal
        :visible="tableVisible"
        title="姓名不一致列表"
        @cancel="listCancel"
        width="600px"
      >
        <a-table
          :dataSource="tableList"
          :columns="columns"
          style="margin-top: 0px"
          :pagination="false"
          :row-key="(record) => record.id"
        >
          <span slot="operation" slot-scope="text, record, index">
            <a @click="openComment(record)">重新生成</a>
          </span>
        </a-table>
      </a-modal>
    </div>

    <!--    修改参会人员   -->
    <ExpertViewone
      v-if="addShow"
      :visible="addShow"
      :addTitle="addTitle"
      :addForm="addForm"
      :expertId="expertId"
      :formPage="formPage"
      :zhuoCardId="zhuoCardId"
      v-on:closeMain="closeMain"
    />
  </a-spin>
</template>

<script>
import {
  getTableCard,
  postTableCard,
  putNewCard,
  getTableCardNum,
  getTableCardNumList,
  getCardType,
  getImgList,
} from "@/service/zhuoCard_api.js";
import { update } from "@/utils/update";
import ExpertViewone from "@/components/meet/ExpertViewone";
import {
  confereeSelectAllNew,
} from "@/service/participants";

export default {
  components: { ExpertViewone },
  props: {
    conventionId: {
      type: String,
    },
    conventionCode: {
      type: String,
    },
  },
  data() {
    return {
      pageSizeOptions: ["12", "24", "36", "48"],
      search: false,
      pageNo: 1,
      pageSize: 12,
      total: 0,
      dataList: [],
      spinning: false,
      drawlVisible: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      name: "",
      addForm: {},
      title: "桌卡生成",
      editId: "",
      num: 0,
      tableList: [],
      tableVisible: false,
      columns: [
        {
          title: "桌卡姓名",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "专家姓名",
          dataIndex: "expertName",
          key: "expertName",
        },
        {
          title: "操作",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      addShow: false,
      addTitle: "编辑",
      addForm1: {},
      expertId: "",
      zhuoCardId: "",
      formPage: "zhuoCard",
      templateList: [],
      cardList: [],
      spin: true,
      show: true,
      canhuiPerson:[],
    };
  },
  created() {
    this.spin = true;
    this.innerWidths = window.innerWidth; //屏幕宽度
  },
  mounted() {
    this.load();
    this.getNum();
    this.getTemplate();
    this.getCardList();
    this.getConfereeSelect();
    let timer = setInterval(() => {
      this.load();
    }, 3000)
    this.$once('hook:beforeDestroy', () => {
      clearInterval(timer)
      timer = null
    })
  },
  methods: {
    //获取参会人员
    async getConfereeSelect() {
      this.spinning = true;
      let data = {
        status:'',
        name:'',
        page:1,
        pageSize:100,
      }
      const response = await confereeSelectAllNew(this.$route.query.code,data)
      if(response.code === 200){
        this.canhuiPerson = response.data;
      }
      this.spinning = false;
    },
    // 获取人数
    async getNum() {
      const res = await getTableCardNum(this.conventionId);
      if (res.code == 0) {
        this.num = res.data;
        //  console.log('1');
      }
    },
    //  获取桌卡
    async load() {
      const res = await getTableCard(this.conventionId, "", 1, 12);
      if (res.code == 0) {
        this.dataList = res.data;
      }
    },
    async getCardList() {
      const res = await getTableCard(
        this.conventionId,
        this.name,
        this.pageNo,
        this.pageSize
      );
      if (res.code == 0) {
        this.cardList = res.data;
        this.spin = false;
        this.total = res.count;
        //  console.log("2");
      }
    },
    async getTemplate() {
      const res = await getCardType("tableCard");
      if (res.code == 200) {
        this.templateList = res.data;
      }
    },
    PageNOchange(pageNo, pageSize) {
      this.pageNo = pageNo;
      this.pageSize = pageSize;
      this.getCardList();
    },
    onSearch() {
      this.getCardList();
    },
    add() {
      if(this.canhuiPerson.length === 0){
        this.$message.warning("请先添加参会人员")
        return
      }
      this.drawlVisible = true;
      this.addForm.materialType = this.templateList[0].code;
      console.log(this.conventionId);
    },
    async uploadImg() {
      let inputDOM = this.$refs.addImgRect.files[0];
      const image = await update(inputDOM);
      if (image.code === 0) {
        // this.addForm.inputUrl = image.data.url;
        this.$message.success("上传成功");
        this.$set(this.addForm, "inputUrl", image.data.url);
        console.log(this.addForm.inputUrl);
        // this.$refs.ruleFormQ.validateField("business"); //校验上传成功，取消显示请上传
      } else {
        this.$message.warning(image.message);
      }
      document.getElementById("addImgRect").value = null;
      // this.IDImgDisabled = false;
    },
    async addOk() {
      if (this.addForm.materialType == undefined) {
        this.$message.warning("请选择模版！");
        return;
      }
      if (this.addForm.inputUrl == undefined) {
        this.$message.warning("请上传背景图！");
        return;
      }
      this.spinning = true;
      if (this.title == "桌卡生成") {
        const res = await postTableCard(
          this.conventionId,
          this.addForm.inputUrl,
          this.addForm.materialType
        );
        //  this.timer = setTimeout(() => {
        //  console.log("555",res);
        //  }, 5000);
        if (res.code == 0) {
          this.timer = setTimeout(() => {
            this.$message.success("桌卡生成成功！");
            this.drawlVisible = false;
            this.addForm = {};
            this.spinning = false;
            this.load();
            this.pageNo = 1;
            this.pageSize = 12
            this.getCardList();
          }, 5000);
        } else {
          this.spinning = false;
          this.$message.warning(res.message);
        }
      } else {
        const res = await putNewCard(
          this.editId,
          this.addForm.inputUrl,
          this.addForm.materialType
        );

        if (res.code == 0) {
          this.timer = setTimeout(() => {
            this.$message.success("桌卡生成成功！");
            this.drawlVisible = false;
            this.spinning = false;
            this.title = "桌卡生成";
            this.addForm = {};
            this.load();
            // this.look()
            // this.pageNo = 1;
            this.getNum();
            this.getCardList();
          }, 3000);
        } else {
          this.spinning = false;
          this.$message.warning(res.message);
        }
      }
    },
    addClose() {
      this.addForm = {};
      this.drawlVisible = false;
      this.title = "桌卡生成";
    },
    // edit(card){
    //   this.title="修改"
    //   this.editId=card.id
    //   this.drawlVisible=true
    //   this.$set(this.addForm,'inputUrl',card.imageUrl)
    // },
    newAdd(card) {
      if (card.updatedTime == null) {
        this.$message.warning("修改信息后重新生成桌卡！");
        return;
      } else {
        this.editId = card.id;
        this.drawlVisible = true;
        this.addForm.materialType = this.templateList[0].code;
        this.title = "重新生成单个桌卡";
        this.addForm.name = card.nam;
      }
      e;
    },

    async look() {
      const res = await getTableCardNumList(this.conventionId);
      if (res.code == 0) {
        this.tableList = res.data;
        this.tableVisible = true;
      }
    },
    listCancel() {
      this.tableVisible = false;
    },
    async openComment(data) {
      this.editId = data.id;
      this.drawlVisible = true;
      this.title = "重新生成单个桌卡";
    },
    editPeople(row) {
      this.zhuoCardId = row.id;

      this.expertId = row.expertId;
      this.addShow = true;
    },
    downimgLoad(item){
      // var checkList = [item.imageUrl,item.imageUrlBack]
      window.open(item.imageUrl)
    },
    closeMain(val) {
      this.addShow = val;
      console.log("执行", val);
    },
    changeReason(e) {
      this.addForm.materialType = e.target.value;
    },
    async down() {
      this.spin = true;
      let data = {
        ExelName: "桌卡",
      };
      const res = await getImgList(this.conventionCode, data);
      this.spin = false;
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  width: 100%;
  .content-top {
    width: 100%;
    padding: 15px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 10px solid #edeff2;
  }
  .content-list {
    padding: 15px 8px 8px 8px;
    .col {
      display: flex;
      justify-content: space-around;
      // justify-content: center;
    }
    .list {
      width: 90%;
      border: 1px solid #edeff2;
      .title {
        padding: 5px 5px 2px 5px;
        font-weight: bold;
        color: #333333;
      }
      .update {
        padding: 2px 6px;
        color: white;
        background-color: rgb(233, 40, 40);
        font-size: 11px;
        border-radius: 5px;
        margin-right: 4px;
      }
    }
    .unit {
      padding: 0 5px 10px 5px;
      font-weight: bold;
      color: #333333;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
    }
    .btn {
      display: flex;
      justify-content: space-around;
      height: 40px;
      line-height: 40px;
      background-color: #edeff2;
      cursor: pointer;
      width: 100%;
      // background: #333333;
      div {
        width: 49%;
        //  background: rebeccapurple;
        text-align: center;
      }
    }
  }
}

.noList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10%;

}
.formImage {
  display: flex;
  flex-direction: column;
  margin-top: 150px;
}
.image {
  position: absolute;
  top: -150px;
  left: 26px;
  width: 180px;
  height: 140px;
}
//  .upload2Img {
//   border: #DDDDDD 1px dashed;
//   width: 290px;
//   height: 150px;
//   background-color:#fafafa;
//   position: relative;
//   background-image: url("../../assets/add.png");
//   background-size: 50% 70%;
//   background-repeat: no-repeat;
//   background-position: center;
//   cursor: pointer;

// }
// .imgSty {
//     width: 100%;
//     height: 100%;
//     position: absolute;
//     top: 0;
//   }

.uploadImg {
  border: #dddddd 2px dashed;
  width: 290px;
  height: 150px;
  // background-image: url("../../assets/add.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  position: relative;

  .upload_gutter {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}
.ant-radio-wrapper {
  padding-left: 80px;
}
.hhh {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  // background-color: #f0f0f0;
  z-index: -1;
}
// .update {
//   padding: 2px 7px;
//   color: white;
//   background-color: rgb(233, 40, 40);
//   font-size: 12px;
//   border-radius: 5px;
//   margin-left: 4px;
// }
.specialBTN{
 ::v-deep .ant-btn {
   padding: 0 5px;
  }
}
</style>
