<template>
  <a-spin tip="加载中..." :spinning="spinning" style="width:100%;height:100%">
<div class="ElectronicMaterial">
  <div class="left">
    <a-menu
        style="width: 100%;border: none;min-height: 500px"
        v-model="selectId"
        mode="inline"
        @click="handleClick"
    >
      <a-menu-item key="1">
        桌卡
      </a-menu-item>
      <a-menu-item key="2">
        胸卡（参会人员）
      </a-menu-item>
      <a-menu-item key="3">
        胸卡（通用）
      </a-menu-item>
    </a-menu>
  </div>
  <!-- 桌卡 -->
   <div class="content" v-if="selectId[0]==1" >
      <zhuoCard style="width:100%" :conventionId="conventionId" :conventionCode='$route.query.code'></zhuoCard>
  </div>
  <div class="content"  v-else>
    <div v-if="PersonnelList.length==0" style="width:100%">
      <div class="noList">
        <span style="margin-bottom:20px"><a-icon type="frown" style="margin-right:10px" />你还没有生成胸卡</span>
        <a-button type="primary" style="width:100px" @click="reset">生成胸卡</a-button>
      </div>
   </div>
    <div v-if="PersonnelList.length!==0">
      <div class="content-top">
      <div style="display: flex;align-items: center">
        <a-input-search
            placeholder="请输入姓名"
            v-model="name"
            style="width: 18vw;margin-right: 15px"
            enter-button="搜索"
            @search="onSearch"
            allowClear
        />
        <a-alert type="error" show-icon closable v-if="num!==0 && this.ListStatus === 'canhui'" style="width:58%">
          <template slot="message">
            <div style="margin-right: 10px">查询到{{num}}位参会者姓名与胸卡姓名不一致，<a @click="look">点击查看</a></div>
          </template>
        </a-alert>
      </div>
      <div class="specialBTN">
        <a-button type="primary" @click="DownloadAll" style="margin-right:17px">下载全部</a-button>
        <a-button type="primary" @click="reset" v-if="ListStatus === 'canhui'">重新生成</a-button>
        <a-button type="primary" @click="reset" v-if="ListStatus === 'tongyong'" style="padding-right: 17px;padding-left: 17px;">新&nbsp;增</a-button>
      </div>
      </div>
    <div class="content-list" v-if="otherList.length!==0">
      <a-row >
        <a-col
            :span="innerWidths > 0 && innerWidths < 1920 ? 6 :4"
            :style="{marginBottom: '2%',}"
            class="col"
            v-for="item in otherList" :key="item.id + Date.now()">
          <div class="list">
            <!-- <div> -->
              <div >
                <img :src="`${item.imageUrl}?${Date.now()}`" style="width:50%;height: 130px" v-if="item.imageUrl!==null" />
                <img :src="`${item.imageUrlBack}?${Date.now()}`" @click="downimgBackLoad(item.imageUrlBack)" style="width:50%;height: 130px" v-if="item.imageUrlBack!==null" />
              </div>
              <div style="display: flex;">
                <span style="width:50%;height: 130px;display: inline-block; display: flex;justify-content: center;align-items: center;" v-if="item.imageUrl===null">
                  待生成...
                </span>
                <span style="width:50%;height: 130px;display: inline-block; display: flex;justify-content: center;align-items: center;" v-if="item.imageUrlBack===null">
                  待生成...
                </span>
              </div>
            <!-- </div> -->
            <div class="title">
              <div style="display: flex;">
                  <div>
                    姓名：
                  </div>
                    <a-tooltip>
                      <div slot="title">{{item.name}}</div>
                  <div style="  width: 4em;overflow: hidden;text-overflow:ellipsis; white-space:nowrap;">
                   {{item.name}}
                  </div>
                   </a-tooltip>
                   <span v-if="item.isUpdate === 1" class="update">已修改</span>
              </div>
            </div>
            <div class="unit" v-if="ListStatus === 'canhui'">单位：
              <a-tooltip>
                <template slot="title">{{item.hospital}}</template>
                {{item.hospital}}
              </a-tooltip>
            </div>
            <div class="btn">
              <div>
                <!-- <a style="color:rgba(0, 0, 0, 0.65)" @click="editPeople(item)">修改</a> -->
                  <a  @click="downimgLoad(item)"  target="_blank"  style="color:rgba(0, 0, 0, 0.65)">下载</a>
              </div> 
              <a style="color: #d5d5d5">|</a>
              <div>
                <a style="color:rgba(0, 0, 0, 0.65)" @click="editPeople(item)">修改</a>
                  <!-- <a  @click="downimgLoad(item)"  target="_blank"  style="color:rgba(0, 0, 0, 0.65)">下载</a> -->
              </div> 
              <a style="color: #d5d5d5">|</a>
              <div>
                <a style="color:rgba(0, 0, 0, 0.65)"  @click="newAdd(item)">重新生成</a>
              </div>
              
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <a-empty v-if="otherList.length==0" style="margin-top:60px"/>
    <!-- 分页 -->
    <div class="page" v-if="otherList.length!==0">
      <a-pagination
          :total="total" 
          :page-size="pageSize"
          :current="current"
          :show-quick-jumper="true"
          :show-size-changer="true"
          :pageSizeOptions="pageSizeOptions"
          @change="pageChange"
          @showSizeChange="pageChange"
      />
    </div>
    </div>

  </div>
 
   


  <!-- 胸卡（参会人员/通用） -->
  <a-drawer
      :title="title"
      placement="right"
      @close="()=>{this.drawlVisible = false;this.$refs.addItem.resetFields();this.addForm = {exampleImgUrl:''}}"
      :visible="drawlVisible"
      :get-container="false"
      width="65%"
    > 
    <a-form-model
        ref="addItem"
        :label-col="labelCol"
        :model="addForm"
        :rules="addRules"
        :wrapper-col="wrapperCol"
        style="box-sizing: border-box;"
    >
      <a-form-model-item label="选择模板" prop="code" class="formImage">
        <a-radio-group v-model="addForm.code" style="margin-top:220px;" >
          <a-radio :value="this.Templateone.code" style="position: relative;margin-right:130px">
            <img :src="this.Templateone.exampleImgUrl" alt="" class="image">
              模板1
          </a-radio>
          <a-radio :value="this.TemplateSecond.code" style="position: relative;">
            <img :src="this.TemplateSecond.exampleImgUrl" alt="" class="image">
              模板2
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-row :gutter="24"  style="box-sizing: border-box;margin:0 ;padding:30px 0;border-bottom:1px dashed #ccc;">
        <a-col
            :span="12"
          >
          <a-form-model-item label="正面背景图" prop="frontBgPath" required style="margin: 0">
            <a-upload
              :headers="{'x-token': token2, 'token': tokenT}"
              name="file"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :action="header + '/api/admin/common/update'"
              :before-upload="beforeUpload"
              @change="handleChange($event, 'addForm','frontBgPath')"
          >
            <img style="width: 100%; height: 100%" v-if="addForm.frontBgPath" :src="addForm.frontBgPath" />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">
                上传
              </div>
            </div>
            </a-upload>
          </a-form-model-item>
          <!-- <a-form-model-item label="头像(长方形)">
             <div>
               <div style="display: flex">
                 <div class="upload2Img">
                   <input
                       id="inputClear2"
                       ref="addImgRect"
                       accept="image/*"
                       style="width: 100%;height:100%;opacity: 0;"
                       type="file"
                       @change="uploadImg('addImgRect')"/>
                 </div>
                 <div class="upload_gutter2">
                   <img
                       v-if="addForm.headImgRect"
                       :src="addForm.headImgRect"
                       alt=""
                       style="width: 100%;height:100%"/>
                 </div>
               </div>
               <span style="font-size: xx-small">注：尺寸100*100，大小不超过500kb</span>
             </div>
          </a-form-model-item> -->
        </a-col>
        <a-col
            :span="12"
            style="transform: translateX(-30%);"
          >
          <a-form-model-item label="姓名" prop="name"  v-if="ListStatus === 'canhui'">
            <a-input v-model="addForm.name"  placeholder='系统自动获取参会人员姓名生成' disabled /> 
          </a-form-model-item>
          <a-form-model-item label="名称" prop="name" required v-if="ListStatus === 'tongyong'">
            <a-input v-model="addForm.name" placeholder='请输入' />
          </a-form-model-item>
          <a-form-model-item label="位置1" style="margin-bottom:-8px" prop="zm1" v-if="addForm.code === this.TemplateSecond.code">
            <a-input v-model="addForm.zm1" placeholder="请输入" />
            <div style="font-size:12px;transform: translateY(-9px);color: rgba(0, 0, 0, 0.50);">如果需要换行显示，请在需要换行的位置添加空格</div>
          </a-form-model-item>
          <a-form-model-item label="位置2" prop="zm2" v-if="addForm.code === this.TemplateSecond.code">
            <a-input v-model="addForm.zm2" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item label="位置3" prop="zm3" v-if="addForm.code === this.TemplateSecond.code">
            <a-input v-model="addForm.zm3" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="24"  style="box-sizing: border-box;margin:0 ;padding:30px 0 80px 0;">
        <a-col
            :span="12"
          >
          <a-form-model-item label="反面背景图" required prop="backBgPath" style="margin: 0">
            <!-- <img style="width: 100%; height: 100%" v-if="addForm.backBgPath" :src="addForm.backBgPath" /> -->
            <a-upload
              :headers="{'x-token': token2, 'token': tokenT}"
              name="file"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :action="header + '/api/admin/common/update'"
              :before-upload="beforeUpload"
              @change="handleChange($event, 'addForm','backBgPath')"
          >
            <img style="width: 100%; height: 100%" v-if="addForm.backBgPath" :src="addForm.backBgPath" />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">
                上传
              </div>
            </div>
            </a-upload>
          </a-form-model-item>
        </a-col>
        <a-col
            :span="12"
            style="transform: translateX(-30%);"
          >
          <a-form-model-item label="位置1" required prop="fm1" style="margin: 0" class="positionOne" v-if="addForm.code === this.TemplateSecond.code">
            <!-- <img style="width: 100%; height: 100%" v-if="addForm.fm1" :src="addForm.fm1" /> -->
            <a-upload
              :headers="{'x-token': token2, 'token': tokenT}"
              name="file"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :action="header + '/api/admin/common/update'"
              :before-upload="beforeUpload"
              @change="handleChange($event, 'addForm','fm1')"
          >
            <img style="width: 100%; height: 100%" v-if="addForm.fm1" :src="addForm.fm1" />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">
                上传
              </div>
            </div>
            </a-upload>
          </a-form-model-item>
          <a-form-model-item label="位置2" prop="fm2" v-if="addForm.code === this.TemplateSecond.code">
            <a-input v-model="addForm.fm2" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item label="位置3" prop="fm3" v-if="addForm.code === this.TemplateSecond.code">
            <a-input v-model="addForm.fm3" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
    >
      <a-button :style="{ marginRight: '20px' ,width:'90px'}" @click="addClose">
        取消
      </a-button>

      <!--新增  -->
      <a-button type="primary" style="width:90px" @click="addOk" v-if="this.drawType === 'add'">
        确认
      </a-button>
      <a-button type="primary" style="width:90px" @click="editOk" v-if="this.drawType === 'edit'">
        确认
      </a-button>
    </div>
  </a-drawer>

  <a-modal :visible="tableVisible" title="姓名不一致列表"  @cancel="listCancel" width="600px">
          <a-table :dataSource="tableList" :columns="columns" style="margin-top:0px"    :pagination="false"  :row-key="record => record.id">
             <span slot="operation" slot-scope="text, record, index">
                 <a  @click="openComment(record)">重新生成</a>
              </span>
          </a-table>
  </a-modal>


    <!--    修改参会人员   -->
    <ExpertViewone
        v-if="addShow"
        :visible="addShow"
        :addTitle="addTitle"
        :addForm="editForm"
        :expertId="expertId"
        :formPage="formPage"
        :zhuoCardId='CardId'
        v-on:closeMain="closeMain"
    />
</div>
</a-spin>
</template>

<script>
import ExpertViewone from "@/components/meet/ExpertViewone";
import Vue from 'vue';
  import api_header_config from "@/service/api_header_config";
  import {
    getSelectAllBadge,getMaterialTemplate,postGenerateBadge,getSelectAllBadgeCurrency,postGenerateBadgeCurrency,postoneGenerateBadge,
    againConventionBadge,againSmallBadge,postShortBadgeCurrency,postnewBadgeCurrency,postnewShortBadgeCurrency,getNameNotMatching,
    getZIP,getTYZIP
} from "@/service/ElectronicMaterial";
import {
  confereeSelectAllNew,
} from "@/service/participants";
import zhuoCard from './zhuoCard.vue';
export default {
   components: {
    zhuoCard,
    ExpertViewone
  },
name: "ElectronicMaterial",
 props:{
    conventionId:{
      type:String,
    },
    HYtitle:{
      type:String,
    }
  },
//   watch: {
//     // 监听json数据变化，重新渲染文件夹内容
//     PersonnelList(newVal) {
//         this.file_is_show = false
//         if (true) {
//             this.$nextTick(()=>{ // $nextTick 是在 DOM 更新循环结束之后执行延迟回调
//                 this.file_is_show = true
//             })
//         }
//     }
// },
  data(){
  return{
    pageSizeOptions: ['12', '24', '36', '48'],
    // imgSatatus:true,
    otherList:[],
    // file_is_show:true,
    columns: [
          {
            title: '胸卡姓名',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: '专家姓名',
            dataIndex: 'expertName',
            key: 'expertName',
          },
          {
            title: '操作',
            dataIndex: 'operation',
            scopedSlots: { customRender: 'operation' },
          }
        ],
    total:0,
    pageSize:12,
    current:1,
    title:'',
    formPage:'ElectronicMaterial', ///????
    addTitle:'编辑',
    addShow:false,
    spinning:false,
    loading:false,  
    tokenT: JSON.parse(window.localStorage.getItem("yichi_userInfo")).token,
    token2:JSON.parse(window.localStorage.getItem("yichi_userInfo")).id,
    header: api_header_config.api_header,
    addRules: {
      code: [{ required: true,message: '请选择', trigger: 'change'}],
      // phone: [{ required: true,message: '请输入11位手机号', trigger: 'blur',pattern: /^1[3|4|5|7|8][0-9]\d{8}$/,}],
      frontBgPath: [{ required: true, message: '请上传', trigger: 'change' }],
      backBgPath: [{required: true, message: '请上传', trigger: 'change'}],
      fm1: [{required: true, message: '请上传', trigger: 'change'}],
      name: [{required: true, message: '请输入', trigger: 'blur'}],
    },
    labelCol: {span: 6},
    wrapperCol: {span: 18},
    addForm:{frontBgPath:'',backBgPath:'',name:'',zm1:'',zm2:'',zm3:'',fm1:'',fm2:'',fm3:''},
    editForm:{},
    drawlVisible:false,
    selectId:['1'],
    name:'',
    PersonnelList:[{imageUrl:''}],
    innerWidths:null,
    ListStatus:'',
    Templateone:{},
    TemplateSecond:{},
    drawType:'',
    CardId:'',
    conventionMemberId:'',
    expertId:'',
    tableList:[],
    tableVisible:false,
    num:0,
    timer:'',
    canhuiPerson:[],
  }
  },
  created() {
    this.innerWidths = window.innerWidth; //屏幕宽度
    this.getMaterial()
    this.ViewTotal()
  },
  mounted() {
    this.getConfereeSelect()
    // let timer = setInterval(() => {
    //   this.getCard();
    // }, 3000)
    // this.$once('hook:beforeDestroy', () => {
    //   clearInterval(timer)
    //   timer = null
    // })
  },
  methods:{
    //获取参会人员
    async getConfereeSelect() {
      this.spinning = true;
      let data = {
        status:'',
        name:'',
        page:1,
        pageSize:100,
      }
      const response = await confereeSelectAllNew(this.$route.query.code,data)
      if(response.code === 200){
        this.canhuiPerson = response.data;
      }
      this.spinning = false;
    },
    pageChange(page_no, page_size) {
      this.current = page_no
      this.pageSize = page_size
      // this.getFeedback()
      this.getCard()
    },
    // 获取模板
    async getMaterial(){
      const res=await getMaterialTemplate()
      if(res.code === 200){
        this.Templateone = res.data[0]
        this.TemplateSecond = res.data[1] 
      }
    },
    // 胸卡列表(参会人员)
    async getCard(){
      this.spinning=true
      let data = {
        name:this.name, 
        page:this.current,
        pageSize:this.pageSize 
      }
      const res = await getSelectAllBadge(this.$route.query.code,data)
      if(res.code == 200){
        // this.imgSatatus = false
        this.$nextTick(() => {
          this.PersonnelList = res.data
          this.otherList = res.data
        }) 
        // this.PersonnelList.forEach((item,index)=>{
        //   this.PersonnelList[index].imageUrl = item.imageUrl + '?' + Date.now()
        // })
        // this.PersonnelList.forEach((item,index)=>{
        //   this.PersonnelList[index].imageUrlBack = item.imageUrlBack + '?' + Date.now()
        // })
        this.total = res.count
        this.spinning=false 
      }
    
    },
    // 胸卡列表（通用）
    async getCurrencyCard(){
      this.spinning=true
      let data = {
        name:this.name, 
        page:this.current,
        pageSize:this.pageSize 
      }
      const res=await getSelectAllBadgeCurrency(this.$route.query.code,data)
      if(res.code === 200){
        // this.imgSatatus = false
        this.PersonnelList = res.data 
        this.otherList = res.data
      }
      this.total = res.count
      this.spinning=false
    },
    // 点击查看姓名不一样列表
    async look(){
      let data = {
        name:this.name, 
        page:this.current,
        pageSize:this.pageSize ,
        nameNotMatching:1
      }
      const res = await getSelectAllBadge(this.$route.query.code,data)
      if(res.code == 200){
        // console.log("姓名不一致",res)
        this.tableList=res.data
        this.tableVisible=true
      }
       
    },
    add(){
         this.drawlVisible=true
      },
    addClose(){
      this.drawlVisible = false
      // this.$emit("closeMain", flag); //子组件向父组件传值（开关）
    },
    addOk(){
      // this.$refs.addItem.validate(async valid =>{
      //   if(valid){
        // console.log("图",this.addForm)
         this.name = ''
         this.current = 1
        if(!this.addForm.code || this.addForm.code==''){
              this.$message.warning('请选择模板！')
              return
         }
        if(this.addForm.frontBgPath=='' || this.addForm.frontBgPath==null){
              this.$message.warning('请上传正面背景图！')
              return
         }
          if(this.addForm.backBgPath=='' || this.addForm.backBgPath==null){
              this.$message.warning('请上传反面背景图！')
              return
         }
         if(this.addForm.fm1=='' && this.addForm.code === this.TemplateSecond.code  ){
              this.$message.warning('请上传位置1背景图！')
              return
         }
         if( this.addForm.fm1==null && this.addForm.code === this.TemplateSecond.code ){
              this.$message.warning('请上传位置1背景图！')
              return
         }
          const form = this.addForm;  
          let data;
          if(this.ListStatus === 'canhui'){ //参会人员新增
            // 模板1(短参数)
            if(form.code === this.Templateone.code){
                this.postAddInfoList(this.$route.query.code,form.frontBgPath,form.backBgPath,form.code);
              }
            // 模板二(长参数)
            else if(form.code === this.TemplateSecond.code){
                this.postAddTwoInfoList(this.$route.query.code,form.frontBgPath,form.backBgPath,form.code,form.zm1,form.zm2,form.zm3,form.fm1,form.fm2,form.fm3)
            }
          }else if(this.ListStatus === 'tongyong'){
            if( this.addForm.name=='' ){
              this.$message.warning('请填写名称')
              return
         }
            if(form.code === this.Templateone.code){
                this.postglobalTemplate(this.$route.query.code,form.frontBgPath,form.backBgPath,form.code,form.name);
              }
            // 模板二(长参数) 
            else if(form.code === this.TemplateSecond.code){
              // conventionCode,id,frontBgPath,backBgPath,code,name,zm1,zm2,zm3,fm1,fm2,fm3
              this.postglobalTwoTemplate(this.$route.query.code,form.frontBgPath,form.backBgPath,form.code,form.name,form.zm1,form.zm2,form.zm3,form.fm1,form.fm2,form.fm3);
            }
          }
    },
    editOk(){
      // this.$refs.addItem.validate(async valid =>{
      //   if(valid){
      this.name = ''
      this.current = 1
        if(!this.addForm.code || this.addForm.code==''){
              this.$message.warning('请选择模板！')
              return
         }
        if(this.addForm.frontBgPath=='' || this.addForm.frontBgPath==null){
              this.$message.warning('请上传正面背景图！')
              return
         }
          if(this.addForm.backBgPath=='' || this.addForm.backBgPath==null){
              this.$message.warning('请上传反面背景图！')
              return
         }
         if(this.addForm.fm1=='' && this.addForm.code === this.TemplateSecond.code  ){
              this.$message.warning('请上传位置1背景图！')
              return
         }
         if( this.addForm.fm1==null && this.addForm.code === this.TemplateSecond.code ){
              this.$message.warning('请上传位置1背景图！')
              return
         }
          const form = this.addForm;  
          if(this.ListStatus === 'canhui'){ //参会人员修改
            // 模板1(短参数)
            if(form.code === this.Templateone.code){
                this.editCARD(this.$route.query.code,form.frontBgPath,form.backBgPath,form.code,this.conventionMemberId)
              }
            // 模板二(长参数)
            else if(form.code === this.TemplateSecond.code){
                this.editTwoCARD(this.$route.query.code,form.frontBgPath,form.backBgPath,form.code,this.conventionMemberId,form.zm1,form.zm2,form.zm3,form.fm1,form.fm2,form.fm3)
              // }
            }
          }else if(this.ListStatus === 'tongyong'){
            if( this.addForm.name=='' ){
              this.$message.warning('请填写名称')
              return
         }
            if(form.code === this.Templateone.code){
                this.ModifyTemplate(this.$route.query.code,this.CardId,form.frontBgPath,form.backBgPath,form.code,form.name);  
              }
            // 模板二(长参数)
            else if(form.code === this.TemplateSecond.code){
              this.ModifylongTemplate(this.$route.query.code,this.CardId,form.frontBgPath,form.backBgPath,form.code,form.name,form.zm1,form.zm2,form.zm3,form.fm1,form.fm2,form.fm3);
            }
          }
    },
    // 生成全部模板（参会人员--模板1）
    async postAddInfoList(conventionCode,frontBgPath,backBgPath,code){
      this.spinning=true
      const res=await postoneGenerateBadge(conventionCode,frontBgPath,backBgPath,code)
       if(res.code===200){
          this.timer = setTimeout(() => {  
                this.$message.success(res.message)
                this.drawlVisible=false
                this.addForm = {frontBgPath:'',backBgPath:'',name:'',zm1:'',zm2:'',zm3:'',fm1:'',fm2:'',fm3:''}
                this.getCard()
                // this.spinning=false
               }, 5000);

       }
       else{
        this.$message.warning(res.message)
       }
    },
    // 修改单个胸卡  
    async editCARD(conventionCode,frontBgPath,backBgPath,code,conventionMemberId){
      this.spinning=true
      const res=await againConventionBadge(conventionCode,frontBgPath,backBgPath,code,conventionMemberId)
       if(res.code===200){
          this.$message.success(res.message)
          this.drawlVisible=false
          this.addForm = {frontBgPath:'',backBgPath:'',name:'',zm1:'',zm2:'',zm3:'',fm1:'',fm2:'',fm3:''}
          this.getCard()
          this.ViewTotal();
       }
       else{
        this.$message.warning(res.message)
       }
       this.spinning=false
    },
    // 生成全部模板（参会人员--模板2）
    async postAddTwoInfoList(conventionCode,frontBgPath,backBgPath,code,zm1,zm2,zm3,fm1,fm2,fm3){
      this.spinning=true
      const res=await postGenerateBadge(conventionCode,frontBgPath,backBgPath,code,zm1,zm2,zm3,fm1,fm2,fm3)
       if(res.code===200){
          this.timer = setTimeout(() => {  
            this.$message.success(res.message)
            this.drawlVisible=false
            this.addForm = {frontBgPath:'',backBgPath:'',name:'',zm1:'',zm2:'',zm3:'',fm1:'',fm2:'',fm3:''}
            this.getCard()
            // this.$forceUpdate();
            // 卡点
          }, 6000);
       }
       else{
        this.$message.warning(res.message)
       }
    },
    // 修改单个胸卡  
    async editTwoCARD(conventionCode,frontBgPath,backBgPath,code,conventionMemberId,zm1,zm2,zm3,fm1,fm2,fm3){
      this.spinning=true
      const res=await againSmallBadge(conventionCode,frontBgPath,backBgPath,code,conventionMemberId,zm1,zm2,zm3,fm1,fm2,fm3)
       if(res.code===200){
          this.$message.success(res.message)
          this.drawlVisible=false
          this.addForm = {frontBgPath:'',backBgPath:'',name:'',zm1:'',zm2:'',zm3:'',fm1:'',fm2:'',fm3:''}
          this.getCard()
          this.ViewTotal();
       }
       else{
        this.$message.warning(res.message)
       }
       this.spinning=false
    },

    // 新增全部模板  (通用--模板一--短参)
    async postglobalTemplate(conventionCode,frontBgPath,backBgPath,code,name){
      this.spinning=true
      const res=await postnewShortBadgeCurrency(conventionCode,frontBgPath,backBgPath,code,name)
       if(res.code===200){
          this.$message.success(res.message)
          this.drawlVisible=false
          this.addForm = {frontBgPath:'',backBgPath:'',name:'',zm1:'',zm2:'',zm3:'',fm1:'',fm2:'',fm3:''}
          this.getCurrencyCard()
       }
       else{
        this.$message.warning(res.message)
       }
       this.spinning=false
    },
    // 新增全部模板  (通用--模板二--长参)
    async postglobalTwoTemplate(conventionCode,frontBgPath,backBgPath,code,name,zm1,zm2,zm3,fm1,fm2,fm3){
      this.spinning=true  
      const res=await postnewBadgeCurrency(conventionCode,frontBgPath,backBgPath,code,name,zm1,zm2,zm3,fm1,fm2,fm3)
       if(res.code===200){
          this.$message.success(res.message)
          this.drawlVisible=false
          this.addForm = {frontBgPath:'',backBgPath:'',name:'',zm1:'',zm2:'',zm3:'',fm1:'',fm2:'',fm3:''}
          this.getCurrencyCard()
       }
       else{
        this.$message.warning(res.message)
       }
       this.spinning=false
    },

    // 修改模板  (通用--模板一--短参)
    async ModifyTemplate(conventionCode,id,frontBgPath,backBgPath,code,name){
      this.spinning=true  
      const res=await postShortBadgeCurrency(conventionCode,id,frontBgPath,backBgPath,code,name)
       if(res.code===200){
          this.$message.success(res.message)
          this.drawlVisible=false
          this.addForm = {frontBgPath:'',backBgPath:'',name:'',zm1:'',zm2:'',zm3:'',fm1:'',fm2:'',fm3:''}
          this.getCurrencyCard()
          this.ViewTotal();
       }
       else{
        this.$message.warning(res.message)
       }
       this.spinning=false
    },
    // 修改模板  (通用--模板一--长参) 
    async ModifylongTemplate(conventionCode,id,frontBgPath,backBgPath,code,name,zm1,zm2,zm3,fm1,fm2,fm3){
      this.spinning=true  
      const res=await postGenerateBadgeCurrency(conventionCode,id,frontBgPath,backBgPath,code,name,zm1,zm2,zm3,fm1,fm2,fm3)
       if(res.code===200){
          this.$message.success(res.message)
          this.drawlVisible=false
          this.addForm = {frontBgPath:'',backBgPath:'',name:'',zm1:'',zm2:'',zm3:'',fm1:'',fm2:'',fm3:''}
          this.getCurrencyCard()
          this.ViewTotal();
       }
       else{
        this.$message.warning(res.message)
       }
       this.spinning=false
    },

    handleClick(e){
       this.selectId[0]=e.key  
       this.current = 1
       this.pageSize = 12
       this.name = ''
       this.ViewTotal()
       if(e.key === '2'){
        // 参会
         this.ListStatus = 'canhui'
        //  this.title = '胸卡生成（参会人员）'
         this.getCard()
       }else if(e.key === '3'){
        // 通用
         this.ListStatus = 'tongyong'
        //  this.title = '胸卡生成（通用）'
         this.getCurrencyCard()
       }
    },
    onSearch(){
      this.current = 1
      this.pageSize = 12
      // this.imgSatatus = true
      if( this.ListStatus === 'canhui'){
        this.getSearchCard()
      }else if(this.ListStatus === 'tongyong'){
        this.getCurSearchCard()
      }
    }, 
    async getSearchCard(){
      this.spinning=true
      let data = {
        name:this.name, 
        page:this.current,
        pageSize:this.pageSize 
      }
      const res = await getSelectAllBadge(this.$route.query.code,data)
      if(res.code == 200){
        // this.imgSatatus = false
        this.$nextTick(() => {
          this.otherList = res.data
        }) 
        this.total = res.count
        this.spinning=false 
      }
    
    },
    async getCurSearchCard(){
      this.spinning=true
      let data = {
        name:this.name, 
        page:this.current,
        pageSize:this.pageSize 
      }
      const res=await getSelectAllBadgeCurrency(this.$route.query.code,data)
      if(res.code === 200){
        // this.imgSatatus = false
        this.otherList = res.data 
      }
      this.spinning=false
    },
    reset(){
      if(this.canhuiPerson.length === 0){
        this.$message.warning("请先添加参会人员")
        return
      }
      if( this.ListStatus === 'canhui'){
        this.title = '胸卡生成（参会人员）'
      }else if(this.ListStatus === 'tongyong'){
        this.title = '胸卡生成（通用）'
      }
      this.drawlVisible = true
      this.drawType = 'add'
      this.$set(this.addForm, 'code', this.Templateone.code);
    },
    async DownloadAll(){
      this.spinning=true
      if( this.ListStatus === 'canhui'){
        let data = {
          ExelName: this.HYtitle+'胸卡（参会人员）'
        }
        const res=await getZIP(this.$route.query.code,data)
      }else if(this.ListStatus === 'tongyong'){
        let data = {
          ExelName: this.HYtitle +'胸卡（通用）'
        }
        const res=await getTYZIP(this.$route.query.code,data)
      }

      this.spinning=false
    },

    handleChange(info, type , parameter) {
      if(parameter === 'frontBgPath'){
        this.addForm.frontBgPath  = ''
        if (info.file.status === 'uploading') {
          this.loading = true;
          return;
        }
        if (info.file.status === 'done') {
          // Get this url from response in real world.
          this.loading = false
          this[type].frontBgPath  = info.file.response.data.url
          this.$message.success('上传成功')
        }
      }else if(parameter === 'backBgPath'){
        this.addForm.backBgPath  = ''
        if (info.file.status === 'uploading') {
          this.loading = true;
          return;
        }
        if (info.file.status === 'done') {
          // Get this url from response in real world.
          this.loading = false
          this[type].backBgPath  = info.file.response.data.url
          this.$message.success('上传成功')
        }
      }else if(parameter === 'fm1'){
        this.addForm.fm1  = ''
        if (info.file.status === 'uploading') {
          this.loading = true;
          return;
        }
        if (info.file.status === 'done') {
          // Get this url from response in real world.
          this.loading = false
          // this[type].fm1  = info.file.response.data.url
          this.$set(this[type], 'fm1', info.file.response.data.url)
          this.$message.success('上传成功')
        }
      }

    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.$message.error('请上传图片类型的文件!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('图片大小不能超过2MB!');
      }
      return isJpgOrPng && isLt2M;
    },
    editPeople(row){
      this.CardId=row.id
      this.addShow = true;
      this.expertId = row.expertId;
    },
    // down2Load(item){
    //   window.open(item.imageUrlBack,'two')
    // },
    downimgLoad(item){
      var checkList = [item.imageUrl,item.imageUrlBack]
      this.$message.success('如果没有打开两个页面，请关闭浏览器拦截')
      checkList.map((s)=>{
				window.open(s)
			})
    },
    // downimgBackLoad(imageUrlBack){
    //   console.log("没运行？")
    //   window.open(imageUrlBack);
    // },
    // 姓名不一致列表点进来
    async openComment(data){
      this.tableVisible=false
      this.conventionMemberId = data.conventionMemberId
      this.CardId = data.id
      this.drawType = 'edit'
      this.editId=data.id
      this.drawlVisible=true
      this.$set(this.addForm, 'code', this.Templateone.code);
      this.title="重新生成单个胸卡"
      this.addForm.name=data.name
    },
    // 修改重新生成
    newAdd(card){
      if(card.updatedById==null && this.ListStatus === 'canhui'){
            this.$message.warning('修改信息后重新生成胸卡！')
            return
        }
     this.$set(this.addForm, 'code', this.Templateone.code);
     this.conventionMemberId = card.conventionMemberId
     this.CardId = card.id
     this.drawType = 'edit'
     this.editId=card.id
     this.drawlVisible=true
     this.title="重新生成单个胸卡"
     this.addForm.name=card.name
    },
    closeMain(val) {
      this.addShow = val;
    },
    async ViewTotal(){
       const res=await getNameNotMatching(this.$route.query.code)
       if(res.code==200){
        this.num = res.data
       }
       
    },
    listCancel(){
      this.tableVisible=false
    },

  }
}
</script>

<style scoped lang="scss">
.ElectronicMaterial{
  background-color: white;
  min-height: 850px;
  display: flex;
  .left{
    width: 18%;
    border-right: 10px solid #EDEFF2;
  }
  .content{
    width: 90%;
    .content-top{
      width: 100%;
      padding: 15px;
      height: 75px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 10px solid #EDEFF2;
    }
    .content-list{
      padding: 15px 8px 8px 8px;
      .col{
        display: flex;
        justify-content: space-around;
        //justify-content: center;
      }
      .list{
        width: 90%;
        border: 1px solid #EDEFF2;
      }
      .title{
        padding: 5px 5px 2px 5px;
        font-weight: bold;
        color: #333333;
    //     width: 11em;
    // overflow: hidden;
    // /* 显示省略符号来代表被修剪的文本。 */
    // text-overflow:ellipsis; 
    // /* 文本不换行 */
    // white-space:nowrap;
      }
      .update{
      // padding:2px 7px;
      // color:white;
      // background-color: rgb(233, 40, 40);
      // font-size:12px;
      // border-radius: 5px;
      // margin-left:4px
      padding: 2px 6px;
  color: white;
  background-color: rgb(233, 40, 40);
  font-size: 11px;
  border-radius: 5px;
   margin-right: 4px;
  // margin-top:5px
  }
      .unit{
        padding: 0 5px 0px 5px;
        font-weight: bold;
        color: #333333;
        overflow:hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -o-text-overflow:ellipsis;
      }
      .btn{
        display: flex;
        justify-content: space-around;
        height: 40px;line-height: 40px;
        background-color: #EDEFF2;
        color:rgba(0, 0, 0, 0.65);
        margin-top:10px;
        div{
             width:49%;
            //  background: rebeccapurple;
             text-align: center;
          }
      }
    }
  }
}
.formImage{
  border-bottom:1px dashed #ccc;padding-bottom:20px;
  .image{
    position: absolute;top:-210px;left: -2px;
    width:260px;
    height: 200px;
  }
  ::v-deep .ant-col-6{
    width: 13%
  }
}
.upload2Img {
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 120px;
  background-image: url("../../assets/add.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;
}
::v-deep .ant-drawer-body {
  padding:24px 0 24px 0;
}
::v-deep .ant-radio{
  margin-left:90px
}

.upload_gutter2 {
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 120px;
  margin-left: 10px;
}

::v-deep .ant-upload.ant-upload-select-picture-card{
  width:150px;
  height: 200px;
}
.positionOne  ::v-deep .ant-upload.ant-upload-select-picture-card{
  width:160px;
  height: 150px;
}
.noList{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top:10%
}
.page{
  display: flex;
  justify-content: center;
  margin: 20px 0 40px 0;

}
.specialBTN{
 ::v-deep .ant-btn {
    padding: 0 5px;
  }
}
</style>
