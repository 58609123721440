import {get,post,put,del,postById,getZip} from "./base";

export function getSelectAllBadge(conventionCode,data) {
  return get(`/conferenceAffairs/material/selectAllBadge/${conventionCode}`,data);
}
// 获取胸卡模板  /conferenceAffairs/material/selectMaterialLayoutTemplate
export function getMaterialTemplate(){
    return get("/conferenceAffairs/material/selectMaterialLayoutTemplate?type="+'badge')
}
// 生成全部胸卡（参会人员）
export function postGenerateBadge(conventionCode,frontBgPath,backBgPath,code,zm1,zm2,zm3,fm1,fm2,fm3){
	return postById(`/conferenceAffairs/material/generateConventionBadge?conventionCode=${conventionCode}&frontBgPath=${frontBgPath}&backBgPath=${backBgPath}&code=${code}&zm1=${zm1}&zm2=${zm2}&zm3=${zm3}&fm1=${fm1}&fm2=${fm2}&fm3=${fm3}`) 
}
export function postoneGenerateBadge(conventionCode,frontBgPath,backBgPath,code){
	return postById(`/conferenceAffairs/material/generateConventionBadge?conventionCode=${conventionCode}&frontBgPath=${frontBgPath}&backBgPath=${backBgPath}&code=${code}`) 
}
// 修改单个胸卡   
export function againConventionBadge(conventionCode,frontBgPath,backBgPath,code,conventionMemberId){
	return postById(`/conferenceAffairs/material/againGenerateConventionBadge?conventionCode=${conventionCode}&frontBgPath=${frontBgPath}&backBgPath=${backBgPath}&code=${code}&conventionMemberId=${conventionMemberId}`) 
}
export function againSmallBadge(conventionCode,frontBgPath,backBgPath,code,conventionMemberId,zm1,zm2,zm3,fm1,fm2,fm3){
	return postById(`/conferenceAffairs/material/againGenerateConventionBadge?conventionCode=${conventionCode}&frontBgPath=${frontBgPath}&backBgPath=${backBgPath}&code=${code}&conventionMemberId=${conventionMemberId}&zm1=${zm1}&zm2=${zm2}&zm3=${zm3}&fm1=${fm1}&fm2=${fm2}&fm3=${fm3}`) 
}


// 查询生成通用胸卡
export function getSelectAllBadgeCurrency(conventionCode,data){
    return get(`/conferenceAffairs/material/selectAllBadgeCurrency/${conventionCode}`,data)
}

// 修改生成胸卡(通用长参数) 
export function postGenerateBadgeCurrency(conventionCode,id,frontBgPath,backBgPath,code,name,zm1,zm2,zm3,fm1,fm2,fm3){
    return postById(`/conferenceAffairs/material/generateConventionBadgeCurrency?conventionCode=${conventionCode}&id=${id}&frontBgPath=${frontBgPath}&backBgPath=${backBgPath}&code=${code}&name=${name}&zm1=${zm1}&zm2=${zm2}&zm3=${zm3}&fm1=${fm1}&fm2=${fm2}&fm3=${fm3}`)
}
// 新增胸卡无id（传id变修改）
export function postnewBadgeCurrency(conventionCode,frontBgPath,backBgPath,code,name,zm1,zm2,zm3,fm1,fm2,fm3){
    return postById(`/conferenceAffairs/material/generateConventionBadgeCurrency?conventionCode=${conventionCode}&frontBgPath=${frontBgPath}&backBgPath=${backBgPath}&code=${code}&name=${name}&zm1=${zm1}&zm2=${zm2}&zm3=${zm3}&fm1=${fm1}&fm2=${fm2}&fm3=${fm3}`)
}
// ---短参 ---修改
export function postShortBadgeCurrency(conventionCode,id,frontBgPath,backBgPath,code,name){
    return postById(`/conferenceAffairs/material/generateConventionBadgeCurrency?conventionCode=${conventionCode}&id=${id}&frontBgPath=${frontBgPath}&backBgPath=${backBgPath}&code=${code}&name=${name}`)
}
// 短参 --- 新增无id
export function postnewShortBadgeCurrency(conventionCode,frontBgPath,backBgPath,code,name){
    return postById(`/conferenceAffairs/material/generateConventionBadgeCurrency?conventionCode=${conventionCode}&frontBgPath=${frontBgPath}&backBgPath=${backBgPath}&code=${code}&name=${name}`)
}
// 姓名不一致列表
export function getNameNotMatching(conventionCode){
    return get(`/conferenceAffairs/material/nameNotMatching/${conventionCode}`)
}
// 下载(参会人员)
export function getZIP(conventionCode,data){
    return getZip(`/conferenceAffairs/material/downloadAllMemberBadge/${conventionCode}`,data)
}
// 下载(通用)
export function getTYZIP(conventionCode,data){
    return getZip(`/conferenceAffairs/material/downloadAllCurrencyBadge/${conventionCode}`,data)
}